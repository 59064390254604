.mainBody {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: linear-gradient(to right top, #63e19b, #4dd19a, #38c298, #26b294, #17a28f, #11a590, #09a790, #00aa91, #00c095, #00d594, #00ea8f, #14ff84);
  height: 100vh;
  font-family: "Roboto", sans-serif;
}

.mainBody .loader {
  display: flex;
  font-size: 2em;
}

.mainBody .loader .dots {
  display: flex;
  position: relative;
  top: 20px;
  left: -10px;
  width: 100px;
  animation: dots 4s ease infinite 1s;
}

.mainBody .loader .dots div {
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 100%;
  background-color: black;
}

.mainBody .loader .dots div:nth-child(1) {
  width: 0px;
  height: 0px;
  margin: 5px;
  margin-right: 15px;
  animation: show-dot 4s ease-out infinite 1s;
}

.mainBody .loader .dots div:nth-child(4) {
  background-color: transparent;
  animation: dot-fall-left 4s linear infinite 1s;
}

.mainBody .loader .dots div:nth-child(4):before {
  position: absolute;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 100%;
  background-color: black;
  content: "";
  animation: dot-fall-top 4s cubic-bezier(0.46, 0.02, 0.94, 0.54) infinite 1s;
}

@keyframes dots {
  0% {
    left: -10px;
  }
  20%, 100% {
    left: 10px;
  }
}

@keyframes show-dot {
  0%, 20% {
    width: 0px;
    height: 0px;
    margin: 5px;
    margin-right: 15px;
  }
  30%, 100% {
    width: 10px;
    height: 10px;
    margin: 0px;
    margin-right: 10px;
  }
}

@keyframes dot-fall-left {
  0%, 5% {
    left: 0px;
  }
  100% {
    left: 200px;
  }
}

@keyframes dot-fall-top {
  0%, 5% {
    top: 0px;
  }
  30%, 100% {
    top: 50vh;
  }
}